html {
  font-size: 62.5%;
}

a:not(.MuiListItem-button), a:not(.MuiListItem-button):link, a:not(.MuiListItem-button):hover, a:not(.MuiListItem-button):focus, a:not(.MuiListItem-button):active {
  text-decoration: none;
  color: #0c87cf;
}

.grecaptcha-badge { 
  visibility: hidden;
}

/* .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #cdcdcd !important;
} */

.MuiListItem-root.Mui-selected:after {
    content: '\2713';
    position: absolute;
    right: 10px;
    color: green;
}